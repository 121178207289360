<template>
      <div id="more" class="mod-featured entry2">
            <div class="mod-featured__img entry2">
                <img class="mod-featured__img--img" src="assets/img/examples/1-ui.png" alt="">
            </div>
            <div class="mod-featured__info">
                <span class="mod-featured__info--span">
                    Etiqueta
                </span>
                <h2 class="mod-featured__info--h2">
                    Títular de la explicación sobre el módulo
                </h2>
                <p class="mod-featured__info--p">
                    Donec mattis nulla lacus, ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur 
                </p>
            </div>
        </div>

</template>