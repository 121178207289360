<template>
    <TopBar></TopBar>
    <main class="main">
        <HeroSection :modulo="modulo"></HeroSection>
        <VideoSection></VideoSection>
        <FeaturedSection></FeaturedSection>
        <CoreSection></CoreSection>
    </main>
    <FooterBar />
</template>
<script lang="ts" setup>
import TopBar from '../components/TopBar.vue'
import FooterBar from '@/components/FooterBar.vue';
import CoreSection from '@/components/services/CoreSection.vue';
import FeaturedSection from '@/components/services/FeaturedSection.vue';
import HeroSection from '@/components/services/HeroSection.vue'
import VideoSection from '@/components/services/VideoSection.vue';
import Modulos from '@/entities/Modulos';
import ModulosService from '@/services/ModulosService';
import { onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const modulosService: Ref<ModulosService> = ref(new ModulosService())
const modulo: Ref<Modulos> = ref<Modulos>({name:'',image:'',index:0,short_descript:''})
const serviceId: number = parseInt(route.params.id.toString())

onMounted(async () => {
    await getModulo()
})

const getModulo = async () => {
    modulo.value = await modulosService.value.getModuloById(serviceId)
}



</script>