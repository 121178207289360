<template>
     <div class="mod-video">
            <div class="mod-video__video entry3">
                <video class="mod-video__video--video" src="assets/img/examples/3-ui.mp4" autoplay loop controls></video>
            </div>
            <div class="mod-video__info entry2">
                <p class="mod-video__info--p">
                    Donec mattis nulla lacus, ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur 
                </p>
                <p class="mod-video__info--p">
                    Donec mattis nulla lacus, ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur sed massa nulla. Mauris faucibus est quam, sed blandit ac gravida turpis condimentum ac. Curabitur 
                </p>
            </div>
        </div>
</template>