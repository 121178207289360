<template>
    <div class="mod-core">

        <div class="mod-core__logo">
            <img class="mod-core__logo--logo" src="assets/icons/modules/exalab-core.svg" alt="">
        </div>

        <div class="mod-core__container">

            <svg class="mod-core__container--arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
            <!-- <img class="mod-core__container--arrow" src="assets/icons/modules/arrow-right-s-line.svg" alt=""> -->

            <a href="#" class="mod-core__container--a active">
                <!-- Solicitud de Atención Icono -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 52.09" fill="currentColor">
                    <ellipse cx="7.33" cy="5.44" rx="5.5" ry="5.44"/>
                    <path d="M7.33,11.79c-4.05,0-7.33,3.25-7.33,7.26h14.67c0-4.01-3.28-7.26-7.33-7.26Z"/>
                    <rect x="21.08" y="1.54" width="33.92" height="6"/>
                    <rect x="21.08" y="13.33" width="20.17" height="6"/>
                    <path d="M7.33,44.56c-4.05,0-7.33,3.25-7.33,7.26h14.67c0-4.01-3.28-7.26-7.33-7.26Z"/>
                    <ellipse cx="7.33" cy="38.21" rx="5.5" ry="5.44"/>
                    <rect x="21.08" y="34.3" width="33.92" height="6"/>
                    <rect x="21.08" y="46.09" width="20.17" height="6"/>
                </svg>
                <span>Solicitud de atención</span>
            </a>

            <a href="#" class="mod-core__container--a">
                <!-- Atención al paciente -->
                <svg class="mod-hero__module--i" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.33398 0V2H2.33398V6C2.33398 8.2091 4.12484 10 6.33398 10C8.54308 10 10.334 8.2091 10.334 6V2H8.33398V0H11.334C11.8863 0 12.334 0.44772 12.334 1V6C12.334 8.9727 10.1721 11.4405 7.33478 11.9169L7.33398 13.5C7.33398 15.433 8.90098 17 10.834 17C12.3313 17 13.609 16.0598 14.1089 14.7375C13.0623 14.27 12.334 13.2201 12.334 12C12.334 10.3431 13.6771 9 15.334 9C16.9909 9 18.334 10.3431 18.334 12C18.334 13.3711 17.4142 14.5274 16.158 14.8854C15.5442 17.252 13.3932 19 10.834 19C7.79638 19 5.33398 16.5376 5.33398 13.5L5.33417 11.9171C2.49636 11.4411 0.333984 8.9731 0.333984 6V1C0.333984 0.44772 0.781704 0 1.33398 0H4.33398ZM15.334 11C14.7817 11 14.334 11.4477 14.334 12C14.334 12.5523 14.7817 13 15.334 13C15.8863 13 16.334 12.5523 16.334 12C16.334 11.4477 15.8863 11 15.334 11Z" fill="#29E8A9"/>
                </svg>
                <span>Atención al paciente</span>
            </a>

            <a href="#" class="mod-core__container--a">
                <!-- Operaciones -->
                <svg class="mod-hero__module--i" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.33398 18V12H15.334V18H18.334V2H4.33398V18H7.33398ZM9.33398 18H13.334V14H9.33398V18ZM20.334 18H22.334V20H0.333984V18H2.33398V1C2.33398 0.44772 2.7817 0 3.33398 0H19.334C19.8863 0 20.334 0.44772 20.334 1V18ZM10.334 6V4H12.334V6H14.334V8H12.334V10H10.334V8H8.33398V6H10.334Z" fill="currentColor"/>
                </svg>
                <span>Operaciones</span>
            </a>

            <a href="#" class="mod-core__container--a">
                <!-- Firma y emisión de informes -->
                <svg class="mod-hero__module--i" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.334 4.75736L16.334 6.75736V2H7.33399V7H2.33398V18H16.334V15.2426L18.334 13.2426V19.0082C18.334 19.556 17.8891 20 17.3406 20H1.32738C0.778745 20 0.333984 19.5501 0.333984 18.9932V6L6.33718 0H17.3318C17.8853 0 18.334 0.45531 18.334 0.9918V4.75736ZM19.1122 6.80761L20.5264 8.2218L12.7482 16L11.3319 15.9979L11.334 14.5858L19.1122 6.80761Z" fill="#29E8A9"/>
                </svg>
                <span>Firma y emisión de informes</span>
            </a>

            <a href="#" class="mod-core__container--a">
                <!-- Finanzas -->
                <svg class="mod-hero__module--i" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.33398 0V16H18.334V18H0.333984V0H2.33398ZM17.6269 3.29289L19.0411 4.70711L13.334 10.4142L10.334 7.415L6.04109 11.7071L4.62687 10.2929L10.334 4.58579L13.334 7.585L17.6269 3.29289Z" fill="#29E8A9"/>
                </svg>
                <span>Finanzas</span>
            </a>

            <a href="#" class="mod-core__container--a">
                <!-- Configuración de parametros clínicos -->
                <svg class="mod-hero__module--i" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.33398 4.30469V1.30469C4.33398 0.752407 4.7817 0.304688 5.33398 0.304688H11.7482L13.7482 2.30469H19.334C19.8863 2.30469 20.334 2.75241 20.334 3.30469V13.3047C20.334 13.857 19.8863 14.3047 19.334 14.3047H16.334V17.3047C16.334 17.857 15.8863 18.3047 15.334 18.3047H1.33398C0.781704 18.3047 0.333984 17.857 0.333984 17.3047V5.30469C0.333984 4.75241 0.781704 4.30469 1.33398 4.30469H4.33398ZM4.33398 6.30469H2.33398V16.3047H14.334V14.3047H4.33398V6.30469ZM6.33398 2.30469V12.3047H18.334V4.30469H12.9198L10.9198 2.30469H6.33398Z" fill="#29E8A9"/>
                </svg>
                <span>Configuración de parámetros clínicos</span>
            </a>

        </div>

</div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue';

onMounted(()=>{
    floatingCore()
})

const floatingCore=()=> {
    // Selecciona el ícono de flecha y el contenedor
    const arrow = document.querySelector('.mod-core__container--arrow');
    const modCore = document.querySelector('.mod-core');

    // Verifica si los elementos existen antes de agregar el evento
    if (arrow && modCore) {
        // Función para verificar el tamaño de la ventana
        const checkScreenSize=()=> {
            if (window.innerWidth <= 767) { // Puedes ajustar el tamaño según tus necesidades
                modCore.classList.add('hide');
            } else {
                modCore.classList.remove('hide');
            }
        }

        // Inicializa la visibilidad al cargar la página
        checkScreenSize();

        // Añade un manejador de eventos para el clic
        arrow.addEventListener('click', function () {
            modCore.classList.toggle('hide');
        });

        // Vuelve a comprobar el tamaño de la ventana cuando se redimensiona
        window.addEventListener('resize', checkScreenSize);
    } else {
        console.warn('El ícono de flecha o el contenedor no se encontraron en el DOM.');
    }
}
</script>