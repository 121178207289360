<template>
    <div id="floatingHeader" class="header header--floating">

        <div class="header__container container">

            <div class="header__logo header__column header__column--1" id="floatingLogo">
                <a class="header__logo--a" href="#">
                    <img src="../assets/img/exalab-logo.svg" alt="logo" class="header__logo--img">
                </a>
            </div>

            <nav class="header__nav nav header__column header__column--2" id="floatingMenu">
                <button id="headerSearch-mobile-float" class="header__nav--search">
                    <i class="ri-search-line"></i>
                </button>

                <button id="toggleMenu-mobile-float" class="header__nav--menu">
                    <i class="ri-menu-line"></i>
                </button>

                <ul class="nav__ul header__menu--list">
                    <li class="nav__li header__menu--li">
                        <RouterLink :to="{name:'home'}" class="nav__a header__menu--link"> Inicio
                            <i class="ri-home-4-line"></i></RouterLink>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-team-line"></i>
                            Nosotros
                        </a>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-stack-line"></i>
                            Productos
                            <i class="ri-arrow-down-s-line"></i>
                        </a>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-chat-smile-2-line"></i>
                            Testimonios
                        </a>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-mail-line"></i>
                            Contacto
                        </a>
                    </li>
                </ul>
            </nav>

            <div class="header__cta header__column header__column--3" id="floatingCta">
                <button id="headerSearch" class="header__cta--search">
                    <i class="ri-search-line"></i>
                </button>
                <RouterLink :to="{name:'price'}" class="header__cta header__cta--link"> Ver Planes <i
                    class="ri-arrow-right-up-line"></i></RouterLink>
            </div>

        </div>

    </div>

    <header id="mainHeader" class="header header--main">

        <div class="header__container container">

            <div class="header__logo header__column header__column--1" id="mainLogo">
                <a class="header__logo--a" href="#">
                    <img src="../assets/img/exalab-logo.svg" alt="logo" class="header__logo--img">
                </a>
            </div>

            <nav class="header__nav nav header__column header__column--2" id="mainMenu">

                <button id="headerSearch-mobile" class="header__nav--search">
                    <i class="ri-search-line"></i>
                </button>

                <button id="toggleMenu-mobile" class="header__nav--menu">
                    <i class="ri-menu-line"></i>
                </button>

                <ul class="nav__ul header__menu--list">
                    <li class="nav__li header__menu--li">
                        <RouterLink :to="{name:'home'}" class="nav__a header__menu--link"> Inicio
                            <i class="ri-home-4-line"></i></RouterLink>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-team-line"></i>
                            Nosotros
                        </a>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-stack-line"></i>
                            Productos
                            <i class="ri-arrow-down-s-line"></i>
                        </a>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-chat-smile-2-line"></i>
                            Testimonios
                        </a>
                    </li>
                    <li class="nav__li header__menu--li">
                        <a href="#" class="nav__a header__menu--link">
                            <i class="ri-mail-line"></i>
                            Contacto
                        </a>
                    </li>
                </ul>
            </nav>

            <div class="header__cta header__column header__column--3" id="mainCta">
                <button id="headerSearch" class="header__cta--search">
                    <i class="ri-search-line"></i>
                </button>
                <RouterLink :to="{name:'price'}" class="header__cta header__cta--link"> Ver Planes <i
                    class="ri-arrow-right-up-line"></i></RouterLink>
            </div>

        </div>

    </header>
</template>